import axios from 'axios'
import store from '../store'

axios.defaults.withCredentials = true

const http = {
    install(Vue) {
        Vue.prototype.$http = config => {
            const promise = new Promise((resolve, reject) => {
                if (!config.method) {
                    config.method = 'POST'
                }

                if (!config.data) {
                    config.data = {}
                }

                config.headers = {
                    'Authorization': store.state.token
                }

                if (/get/i.test(config.method) && config.data) {
                    config.params = {
                        ...config.data
                    }
                }

                axios(config).then(result => {
                    if (result.status === 200 && result.data) {
                        resolve(result.data)
                    } else {
                        reject(`${config.url}接口数据错误`)
                    }
                }).catch(e => {
                    reject(`${config.url}接口自身错误===>${e}`)
                })
            })

            return promise
        }
    }
}

export default http
