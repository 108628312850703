<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import cookie from 'js-cookie'
import { mapMutations } from 'vuex'

export default {
    created() {
        let token = cookie.get('lc_token')

        if (token) {
            this.setToken(token)
            this.$router.replace({
                name: 'index'
            })
        } else {
            this.$router.replace({
                name: 'login'
            })
        }
    },
    methods: {
        ...mapMutations(['setToken'])
    }
}
</script>

<style lang="less">
</style>
